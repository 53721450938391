.keyword-search {
  position: relative;

  // One weird trick to increase selector specificity
  &#{&} input {
    font-size: 16px;
    padding-left: 42px;
    width: 100%;

    &::placeholder {
      font-size: 14px;
    }

    @include media-breakpoint-up(md) {
      font-size: 14px;
      width: 200px;
    }

    @media screen and (width >= 830px) {
      width: 250px;
    }
  }
}
