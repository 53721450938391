.edit-form {
  &-container {
    margin: 0 auto;
    max-width: 700px;
    padding-bottom: 150px;
    position: relative;
  }

  &-controls {
    --padding-x: 20px;

    align-items: center;
    background-color: $white;
    bottom: 0;
    display: flex;
    height: 75px;
    justify-content: space-between;
    left: 0;
    padding: 0 var(--padding-x) 0 calc(var(--padding-x) - 18px);
    position: fixed;
    right: 0;
    transform-style: preserve-3d;
    z-index: 1;

    &::after {
      box-shadow: 0 0 11px hsl(0deg 0% 0% / 30%);
      content: '';
      height: 1px;
      left: 5px;
      position: absolute;
      right: 0;
      top: 1px;
      transform: translateZ(-1px);
    }

    @include media-breakpoint-up(lg) {
      --padding-x: 60px;

      left: 320px;
      padding: 0 60px 0 60 - 18px;
    }
  }
}
