.share-modal-content {
  &,
  &.z-modal-content {
    @include media-breakpoint-down(md) {
      top: auto;
      bottom: -172px;
      animation: slide-up .7s;
      box-shadow: none;
    }
  }
}

.share-modal-copy-button {
  top: -55px;
  position: relative;
  margin-left: auto;
}

@keyframes slide-up {
  0% {
    bottom: -516px;
  }

  100% {
    bottom: -172px;
  }
}
