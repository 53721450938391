.hero-text-block {
  @include block-padding;

  background-color: $white;

  .content {
    @include body-text;
    @include responsive(font-size, 16px, $lg: 18px, $xxl: 22px);

    h2 {
      @include responsive(font-size, 42px, $lg: 45px, $xxl: 60px);
      @include display-hed;

      margin-bottom: 1.5rem;
    }
  }

  .cta {
    margin-top: 45px;
  }
}
