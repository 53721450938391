.dashboard {
  &-wrapper {
    display: flex;
  }

  &-listings-wrap {
    min-height: 60vh;
    padding: 30px 0;

    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }
  }

  &-mobile-header {
    &-nav {
      border-top: 1px solid $border-color;
      display: flex;
      padding: 0 16px;
      overflow: auto;
      width: 100%;
    }

    &-nav-item {
      border-bottom: 2px solid transparent;
      color: $gray400;
      display: block;
      font-size: 15px;
      font-weight: $font-weight-bold;
      height: 50px;
      line-height: 50px;
      margin: 0 12px;
      text-decoration: none;
      white-space: nowrap;

      &.active {
        border-color: $text-primary;
        color: $text-primary;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-sidebar {
    $horiz-padding: 66px;

    background-color: $white;
    flex: 0 0 320px;
    transform-style: preserve-3d;
    z-index: 2;

    &::after {
      bottom: 0;
      box-shadow: 0 4px 11px hsl(0deg 0% 0% / 30%);
      content: '';
      position: absolute;
      right: -1px;
      top: 5px;
      transform: translateZ(-1px);
      width: 1px;
    }

    &-content {
      padding: 60px $horiz-padding;
      position: sticky;
      top: 0;
    }

    &-nav {
      margin: 0 -#{$horiz-padding} 150px;
    }

    &-nav-item {
      border-left: 6px solid transparent;
      display: block;
      font-size: 17px;
      font-weight: $font-weight-black;
      margin: 2px 0;
      padding: 16px #{$horiz-padding - 6px};
      transition: all .15s linear;

      &,
      &:hover {
        color: $text-primary;
        text-decoration: none;
      }

      &.active {
        background-color: $primary-hover;
        border-color: $primary;
        color: $text-primary;
      }

      &:not(.active):hover {
        background-color: $primary-hover;
        border-color: $primary-hover;
        color: $text-primary;
      }
    }

    .user-avatar {
      margin-bottom: 68px;
    }

    .link-list {
      margin-left: -#{$horiz-padding};
      margin-right: -#{$horiz-padding};
    }

    .link-list-item {
      padding-left: $horiz-padding;
      padding-right: $horiz-padding;
    }
  }

  &-main {
    flex: 1;
    padding: 15px;

    @include media-breakpoint-up(md) {
      min-height: calc(100vh - 80px);
      padding: 40px 60px;
    }

    @include media-breakpoint-down(md) {
      .edit-form-container {
        margin: 0 -15px;
      }
    }
  }
}
