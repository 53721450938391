.btn-circle {
  align-items: center;
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 50%;
  box-shadow: 0 2px 12px rgb(0 0 0 / 8%);
  color: $text-primary;
  cursor: pointer;
  display: flex;
  flex: 0 0 32px;
  height: 32px;
  justify-content: center;
  transition: all .15s linear;
  width: 32px;

  &.disabled {
    cursor: auto;
    opacity: .5;
  }

  &:not(.disabled, .active):hover {
    background-color: $bg-med;
    border-color: $bg-med;
  }

  i,
  svg {
    display: block;
    line-height: 1;
  }
}
