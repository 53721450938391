.user-avatar {
  align-items: center;
  border-radius: 50%;
  color: $primary;
  display: flex;
  font-size: 28px;
  font-weight: $font-weight-black;
  height: 100px;
  justify-content: center;
  line-height: 1;
  text-transform: uppercase;
  width: 100px;
  background-color: #f2f2f2;

  &:hover {
    text-decoration: none;
  }
}
