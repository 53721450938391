.small-text-block {
  @include block-padding;

  background-color: $block-bg-alt;

  .body {
    @include body-text;

    h3 {
      @include responsive(font-size, 24px, $md: 30px, $xl: 30px);

      font-weight: 600;
      margin-bottom: 1.5rem;
    }
  }
}
