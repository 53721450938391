.event-search-block {
  @include block-padding;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;

  &.center {
    align-items: center;
    text-align: center;

    .event-search-block-form {
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(md) {
    &.hero {
      min-height: calc(100vh - 70px);
    }
  }

  h1 {
    @include responsive(font-size, 50px, $md: 60px, $xl: 80px);
  }

  h2 {
    @include responsive(font-size, 40px, $md: 55px, $xl: 70px);
  }

  .content {
    @include body-text;

    color: inherit;
    font-size: 16px;
    margin-bottom: 20px;
    text-shadow: 0 0 8px rgb(0 0 0 / 70%);

    h1,
    h2,
    h3 {
      margin-bottom: 1.5rem;

      a {
        @include reset-link;
      }
    }
  }

  &-form {
    .subtle-focus {
      background-color: rgba(#fff, .8);
      border-color: transparent;

      span {
        color: $text-secondary;
        font-weight: 400;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &-form {
      .dropdown-button-wrap {
        display: block !important;
        margin-bottom: 15px;
        margin-right: 0 !important;

        .btn-z {
          width: 100% !important;
        }
      }

      .submit-button {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    align-items: start;
    text-align: left;

    &-form {
      background-color: rgba(#fff, .8);
      border-radius: 50rem;
      display: flex;
      padding: 8px;
      width: max-content;

      .subtle-focus {
        background-color: transparent;
        min-width: 250px;
      }

      .dropdown-button-wrap:first-child {
        border-right: 1px solid rgba(#000, .2);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .content {
      font-size: 24px;
      margin-bottom: 35px;
    }

    &-form {
      .btn-z {
        font-size: 18px;
        height: 49px;
        padding: 14px 18px 15px;
      }
    }
  }
}
