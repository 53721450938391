
#d_editor_bar {
  width: 100%;
  font-family: Mukta, Helvetica, Arial, sans-serif;
  color: white;

  a,
  a:visited {
    color: white;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $green400;
    }
  }

  a.active {
    color: $green400;
    text-decoration: underline;
  }

  .dropdown-item,
  a.dropdown-item:visited,
  a.dropdown-item:hover {
    color: var(--bodytext);

    &:hover {
      text-decoration: none;
    }
  }

  .dropdown-item.header {
    background: var(--outline);
    padding-left: 1rem;
  }

  ul.editor_bar__nav {
    justify-content: space-between;
    align-items: center;

    input {
      font-size: 80%;
      width: 10rem;
    }

    form {
      display: inline-block;
    }

    li {
      padding: .5rem 1rem;
    }
  }
}

#d_reports_nav,
#d_profile_nav {
  width: 100%;

  li {
    padding: .5rem 1rem;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.editor__stats {
  display: flex;

  .editor__stat {
    padding: 1rem;
    margin: 1rem;
    border: 1px solid var(--outline);
    border-radius: .25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .editor__stat:hover {
    background: #f6f6f6;
  }

  .editor__stat_value {
    font-size: 300%;
  }
}

.table-container {
  .pagination {
    display: flex;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.editor.table {
  thead th {
    border-bottom: 1px solid $gray300 !important;
    vertical-align: middle;
    font-size: .85rem;

    a,
    a:visited {
      color: var(--bodytext);
    }

    a:hover {
      color: $green400;
      text-decoration: underline;
    }

    a {
      text-decoration: none;
    }
  }

  th.asc a {
    &::before {
      content: "\2227";
      padding-right: .25rem;
    }
  }

  th.desc a {
    &::before {
      content: "\2228";
      padding-right: .25rem;
    }
  }

  td,
  th {
    padding: .25rem;
    vertical-align: middle;
    border-top: 0;
  }

  .c {
    text-align: center;
  }

  .l {
    text-align: left;
  }

  tr.even {
    background: $gray50;
  }

  .alert {
    margin-bottom: 0;
  }
}

.editor__events,
.editor__pois {
  text-align: center;
}

.editor__username {
  a:hover {
    text-decoration: underline;
  }

  a {
    text-decoration: none;
  }
}
