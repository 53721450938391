.category-list {
  display: grid;
  grid-auto-rows: min-content;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  &-item {
    align-items: center;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 4px;
    color: $text-secondary;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: 6px 12px;
    transition: all .15s linear;
    font-size: 14px;

    &:hover {
      background-color: $blue-xlight;
    }

    &.selected {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
  }

  &-item-label {
    flex: 1;
    font-weight: $font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-item-check {
    color: #fff;
    opacity: 0;
    transition: opacity .15s linear;

    .selected & {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &-item {
      font-size: 14px;
      padding: 10px 6px;

      > i {
        display: none;
      }
    }

    &-item-check {
      display: none;
    }
  }
}
