// copied from "~bootstrap/scss/bootstrap.scss";

// Variable overrides
$enable-negative-margins: true;
$font-family-sans-serif: "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;
$font-weight-bold: 600;
$font-weight-bolder: 600;
$h6-font-size: 1.0625rem;
$btn-font-size-lg: 1rem;
$btn-padding-y-lg: .7rem;
$primary: $blue-primary;
$success: $green400;
$danger: $red400;
$warning: $yellow400;
$info: $platinum200;
$body-color: $text-primary;
$text-muted: $text-secondary;
$min-contrast-ratio: 3;
$border-radius-lg: 8px;
$box-shadow: 0 .5rem 1rem rgba(#000, .075);

// extend default list of spacing helpers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
);

// Override to add hd breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  hd: 1600px
);


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layou"~bootstrap/scss/ & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";

// Helper~bootstrap/scss/s
@import "~bootstrap/scss/helpers";

// Utilit~bootstrap/scss/ies
@import "~bootstrap/scss/utilities/api";

.alert {
  > :last-child {
    margin-bottom: 0;
  }

  &-error {
    @extend .alert-danger;
  }
}

.table th {
  font-weight: $font-weight-bold;
}
