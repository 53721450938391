.poi-open-hours {
  &-row {
    color: $text-secondary;
    display: flex;
    margin-bottom: 10px;

    &.current-day {
      font-weight: $font-weight-bold;
    }

    .dow {
      flex: 0 0 65px;
    }

    .time {
      flex: 0 0 150px;
    }
  }
}
