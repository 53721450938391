.image-field-widget {
  display: flex;
  position: relative;

  &-dropzone {
    align-items: center;
    background-color: $blue-superlight;
    border: 1px solid $border-color;
    border-radius: 8px;
    color: $text-tertiary;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    min-height: 200px;
    transition: border-color .15s linear;

    &.active {
      border-color: $blue-primary;
    }

    &.reject {
      border-color: $error-color;
    }

    i {
      color: $text-light;
      font-size: 42px;
      margin-bottom: 20px;

      svg {
        display: block;
      }
    }

    h6 {
      color: $text-primary;
      font-weight: 400;
    }
  }

  &-image-container {
    background-color: $bg-lt;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;

    header {
      color: $text-tertiary;
      margin-bottom: 20px;
      padding: 5px 0;
      position: relative;
      text-shadow: 0 -1px 0 #fff;

      &::after {
        background-color: $border-color;
        bottom: 0;
        content: '';
        height: 1px;
        left: -20px;
        position: absolute;
        right: -20px;
      }
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    & + & {
      border-left: 0;
    }
  }

  &.error &-image-container {
    border-color: $error-color;
  }
}
