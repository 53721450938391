// colors
$black: #000;
$blue100: #eff3fe;
$blue200: #b5d7f7;
$brown100: #ebe0db;
$brown200: #d2bbb0;
$gray50: #f6f6f6;
$gray100: #eee;
$gray200: #e2e2e2;
$gray300: #cbcbcb;
$gray400: #afafaf;
$gray500: #757575;
$gray600: #545454;
$green100: #d9ede4;
$green200: #87d9b2;
$green400: #05944f;
$orange100: #ffe1d6;
$orange200: #fabda5;
$platinum100: #ebf5f7;
$platinum200: #b0cfd9;
$purple100: #e6ddf2;
$purple200: #cab5e3;
$red100: #fed7d2;
$red200: #f1998e;
$red400: #e11900;
$white: #fff;
$yellow100: #fff4d9;
$yellow200: #fadd98;
$yellow400: #f2c14f;
$zc100: #d9ddfb;
$zc200: #abb4ff;
$zc300: #6f7dff;
$zc400: #1e3dff;
$zc500: #011dc2;
$blue-primary: $zc400;
$blue-light: $zc200;
$blue-xlight: $zc100;
$blue-superlight: #ebecfa;
$primary-disabled: $blue-light;
$primary-hover: $blue-xlight;
$accent: $yellow400;
$text-primary: $black;
$text-secondary: $gray600;
$text-tertiary: $gray500;
$text-light: $gray300;
$text-light-alt: $gray400;
$font-weight-black: 700;
$border-color: $gray200;
$border-color-light: $gray50;
$bg-lt: $gray50;
$bg-med: $gray100;
$error-color: $red400;
$success-color: $green400;
$badge-colors: "purple" $purple100 $purple200,
  zc $zc100 $zc200,
  "blue" $blue100 $blue200,
  "green" $green100 $green200,
  "yellow" $yellow100 $yellow200,
  "orange" $orange100 $orange200,
  "red" $red100 $red200,
  "brown" $brown100 $brown200,
  "platinum" $platinum100 $platinum200;

// spacing

$page-horizontal-padding: var(--page-horizontal-padding);

// Legacy colors

$legacy-dark-blue: rgb(0 51 78);
$legacy-bright-green: rgb(127 209 36);
$legacy-bright-blue: #007bff;
