.saved-address-card {
  &-title {
    color: red;
  }

  &-edit-button {
    transition: color .15s linear;
    color: $text-primary;

    &:hover {
      color: $blue-primary;
    }
  }
}
