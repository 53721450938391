.checkbox-label {
  align-items: center;
  color: $text-secondary;
  cursor: pointer;
  display: inline-flex;
  font-weight: $font-weight-bold;
  line-height: 1;
  margin: 0;
  white-space: nowrap;

  > i {
    flex: 0 0 30px;
  }
}
