.radio-list,
.checkbox-list,
.link-list {
  margin: 0;
  padding: 0;

  &-item {
    align-items: center;
    background-color: white;
    color: $text-secondary;
    cursor: pointer;
    display: flex;
    font-weight: $font-weight-bold;
    margin: 0;
    padding: 10px 0;
    text-decoration: none;
    transition: all .15s linear;

    &:hover {
      color: $text-primary;
    }

    &.selected {
      color: $primary;
    }

    > i {
      flex: 0 0 30px;
    }

    > span {
      display: inline-block;
    }
  }

  &.invert &-item {
    flex-direction: row-reverse;

    > i {
      text-align: right;
    }
  }

  &.stretch &-item {
    justify-content: space-between;
  }
}

.sticky-radio-list-item {
  position: sticky;
  top: 0;
}

.radio-list-item {
  align-items: baseline;
}

.link-list-item {
  &.active,
  &:hover {
    background-color: $blue-xlight;

    // color: $text-secondary;
  }
}
