:root {
  --page-horizontal-padding: 32px;

  @include media-breakpoint-down(md) {
    --page-horizontal-padding: 16px;
  }
}

.bg-lt {
  background-color: $bg-lt;
}

.reset-link {
  @include reset-link;

  &:hover {
    text-decoration: underline;
  }
}

.td-underline-hover {
  &:not(:hover) {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.shadow-clipped {
  height: 20px;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;

  &::before {
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    content: '';
    height: 20px;
    left: 20px;
    position: absolute;
    right: 20px;
    top: -25px;
  }
}

.hairline-divider {
  align-items: center;
  color: $text-primary;
  display: flex;
  font-size: 14px;
  height: 20px;
  justify-content: center;
  line-height: 1;
  overflow: hidden;

  > div {
    padding: 0 10px;
    position: relative;

    &::before,
    &::after {
      background-color: $border-color;
      content: '';
      height: 1px;
      position: absolute;
      top: 50%;
      width: 2000px;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}

// overall page horizontal padding helpers
.px-page {
  padding-left: $page-horizontal-padding;
  padding-right: $page-horizontal-padding;
}

.pl-page {
  padding-left: $page-horizontal-padding;
}

.pr-page {
  padding-right: $page-horizontal-padding;
}

// text color
.color-primary {
  color: $primary !important;
}

.text-primary {
  color: $text-primary !important;
}

.text-secondary {
  color: $text-secondary !important;
}

.text-tertiary {
  color: $text-tertiary !important;
}

.text-light {
  color: $text-light !important;
}

.text-light-alt {
  color: $text-light-alt !important;
}

.fw-black {
  font-weight: $font-weight-black;
}

.text-sm {
  font-size: 12px;
}

.fs-h2 {
  font-size: calc(1.325rem + .9vw);
}

.flex-spacer {
  flex: 1;
}

.col-sm-fullwidth {
  @include media-breakpoint-down(sm) {
    margin-left: calc(var(--bs-gutter-x) / -2);
    margin-right: calc(var(--bs-gutter-x) / -2);

    .row > & {
      flex: 0 0 calc(100% + var(--bs-gutter-x));
      max-width: unset;
    }
  }
}

// mobile-specific heading size adjustments
@include media-breakpoint-down(sm) {
  .h1-sm {
    @include font-size($h1-font-size);
  }

  .h2-sm {
    @include font-size($h2-font-size);
  }

  .h3-sm {
    @include font-size($h3-font-size);
  }

  .h4-sm {
    @include font-size($h4-font-size);
  }

  .h5-sm {
    @include font-size($h5-font-size);
  }

  .h6-sm {
    @include font-size($h6-font-size);
  }
}
