.blog {
  &-wrap {
    // background-color: $bg-lt;
    background-color: $white;
  }

  &-entry.blog-entry {
    border-radius: 0;
    border-top: 3px solid $blue-primary;
    color: $text-secondary;
    cursor: unset;
    margin-bottom: 50px;
    overflow: hidden;
    padding: 12px 0;

    &:hover {
      box-shadow: none !important;
    }

    .img-thumbnail {
      width: 100%;
    }

    footer {
      border-color: $gray300;
    }
  }

  &-entry-image {
    border-radius: 8px;
    display: block;
    max-width: 100%;
  }

  &-entry-meta {
    font-size: 14px;
  }

  &-entry-item {
    border-bottom: 1px solid $gray100;
    margin-bottom: 8px;
    padding-bottom: 8px;

    &:last-child {
      border-bottom: 0;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-sidebar {
    border-radius: 8px;
    background-color: #f6f6f6;
    color: $text-secondary;
    margin-bottom: 50px;
    padding: 30px;
  }
}
