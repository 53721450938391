.cards-block {
  @include responsive(padding, 50px 15px, $md: 70px 35px, $xxl: 70px 140px, $hd: 70px 20%);

  background-color: $block-bg-alt;

  h2 {
    @include responsive(font-size, 38px, $xxl: 50px);
    @include display-hed;
  }

  .cards-container {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 20px;

    @include media-breakpoint-up(md) {
      grid-gap: 35px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xxl) {
      grid-template-columns: repeat(3, 1fr);
    }

    .item {
      @include responsive(padding, 25px, $md: 40px, $xxl: 45px);

      background-color: $white;
      border-radius: 15px;

      .label {
        align-items: center;
        display: flex;
        font-weight: 600;
        gap: 10px;
        margin-bottom: 15px;

        @include responsive(font-size, 21px, $md: 23px, $xxl: 26px);

        .icon-container {
          align-items: center;
          display: flex;
          flex: 0 0 50px;
          justify-content: center;
        }

        .icon {
          font-size: 34px !important;
        }

        svg {
          display: block;
        }
      }

      .description {
        @include body-text;
      }
    }
  }
}
