.filter-bar {
  background-color: $white;
  display: flex;
  padding: 14px $page-horizontal-padding;

  @include media-breakpoint-down(md) {
    padding-top: 0;
  }

  &-mobile-container {
    background-color: $white;
    bottom: 0;
    left: -100%;
    overflow: auto;
    position: fixed;
    top: 0;
    transition: left .4s ease;
    width: 100%;
    z-index: 20;

    &.active,
    &.active > footer {
      left: 0;
    }

    > header {
      align-items: center;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      padding: $page-horizontal-padding;
      position: sticky;
      top: 0;
    }

    > footer {
      background-color: $white;
      bottom: 0;
      left: -100%;
      padding: $page-horizontal-padding;
      position: fixed;
      transition: left .4s ease;
      width: 100%;
    }
  }

  &-mobile-content {
    padding: 25px $page-horizontal-padding 100px;
  }

  .filter-count-badge {
    align-items: center;
    background-color: $zc400;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-size: 11px;
    height: 18px;
    justify-content: center;
    line-height: 1;
    margin-right: 5px;
    width: 18px;
  }
}
