$text-primary-alt: #333;
$block-bg-alt: #edf1ff;

@mixin block-padding {
  @include responsive(padding, 50px 15px, $md: 70px 75px, $xl: 70px 140px, $hd: 70px 20%);
}

@mixin display-hed {
  font-weight: 300;
  line-height: 1.2;

  strong,
  b {
    font-weight: 600;
    color: $primary;
  }
}

@mixin body-text {
  @include responsive(font-size, 16px, $lg: 18px, $xxl: 20px);

  color: $text-primary-alt;
  line-height: 1.7;

  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1em;

    &:last-child {
      margin: 0;
    }
  }

  small {
    @include responsive(font-size, 12px, $lg: 14px);
  }
}
