/* stylelint-disable no-descending-specificity */
.pagination {
  align-items: center;
  display: flex;
  justify-content: center;

  &-item,
  &-separator {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 50%;
    color: $text-primary;
    display: flex;
    flex: 0 0 32px;
    height: 32px;
    justify-content: center;
    margin: 0 4px;
    transition: all .15s linear;
    width: 32px;
  }

  &-item {
    @include reset-link;

    cursor: pointer;

    &.disabled {
      cursor: auto;
      opacity: .5;
    }

    &:not(.disabled, .active):hover {
      background-color: $bg-med;
      border-color: $bg-med;
    }

    &.active {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
  }
}
/* stylelint-enable */
