.stats-dashboard-wrap {
  padding: 40px 0;
}

.metric-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 180px;
  position: relative;
}

.metric-card {
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  display: flex;
  flex: 0 0 180px;
  flex-direction: column;
  height: 180px;
  padding: 10px;

  .label {
    color: $text-tertiary;
    font-size: 14px;
    line-height: 1.1;
  }

  .value {
    align-items: center;
    color: $text-secondary;
    display: flex;
    flex: 1;
    font-size: 48px;
    font-weight: $font-weight-bold;
    justify-content: center;
    line-height: 1;
  }
}

@include media-breakpoint-down(md) {
  .stats-dashboard-wrap {
    padding: 20px 0;
  }

  .metric-card-container {
    gap: 15px;
    min-height: 150px;
  }

  .metric-card {
    flex: 0 0 150px;
    height: 70px;

    .label {
      font-size: 12px;
    }

    .value {
      font-size: 24px;
    }
  }
}
