.claim-venue-form {
  &-wrap {
    border: 4px solid $blue-xlight;
    border-radius: 20px;
    padding: 40px;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 30px 10px;
    }

    header {
      background-color: $white;
      left: 50%;
      padding: 2px 15px;
      position: absolute;
      top: -13px;
      transform: translateX(-50%);

      h5 {
        color: $blue-primary;
        font-size: 18px;
        font-weight: $font-weight-bold;
        letter-spacing: .03em;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .edit-form-container {
      padding-bottom: 0;
    }
  }

  &-menu-extra {
    border-top: 1px solid $border-color;
    font-weight: $font-weight-black;
    padding: 20px 15px 10px;
  }
}
