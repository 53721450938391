.event-mobile-bottom-bar {
  align-items: center;
  background-color: $white;
  border-top: 1px solid $border-color;
  bottom: 0;
  display: flex;
  height: 74px;
  justify-content: space-between;
  left: 0;
  padding: 0 12px;
  position: fixed;
  right: 0;
  z-index: 10;
}
