.site-header {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  color: $text-primary;
  display: flex;
  flex-wrap: wrap;
  height: 76px;
  justify-content: space-between;
  width: 100%;

  &-logo {
    img {
      display: block;
      height: 25px;
    }
  }

  &-spacer {
    flex: 1;
  }

  @include media-breakpoint-up(md) {
    &-search .keyword-search input {
      @include btn-join-right;
    }

    &-location .btn-z.location-button {
      @include btn-join-left;
    }
  }

  @include media-breakpoint-down(md) {
    border-bottom: 0;
    height: unset;
    padding: $page-horizontal-padding;

    &-logo { order: 1; }
    &-spacer { order: 2; }
    &-links { order: 3; }

    &-location {
      flex: 0 0 100%;
      margin-top: 15px;
      order: 4;

      > :first-child {
        display: block !important;
      }

      .btn-z {
        width: 100% !important;
      }
    }

    &-search {
      flex: 0 0 100%;
      margin-top: 10px;
      order: 5;
    }
  }

  a.site-header-link {
    color: inherit;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
      padding-left: 5px;
      padding-right: 5px;
    }

    &.active,
    &:hover {
      color: $primary;
      text-decoration: underline;
      text-decoration-thickness: .1em;
      text-underline-offset: .6em;
    }
  }
}
