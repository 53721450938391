.modal-register-cta {
  border: 1px solid $border-color;
  margin: 75px;
  max-width: calc(100vw - 150px);
  padding: 60px 40px;
  position: relative;
  width: 1000px;

  .logo-container {
    background-color: $white;
    height: 40px;
    left: 50%;
    padding: 0 40px;
    position: absolute;
    top: -20px;
    transform: translateX(-50%);

    svg {
      display: block;
    }
  }

  .columns {
    display: flex;

    > div {
      flex: 1;
    }

    .divider {
      background-color: $border-color;
      display: block;
      flex: 0 0 1px;
      margin: 40px 70px 0;
    }
  }

  .bullet {
    display: flex;
    font-weight: $font-weight-black;
    margin-bottom: 15px;

    i {
      margin-right: 15px;
    }
  }

  @include media-breakpoint-down(md) {
    margin: 50px 10px 10px;
    max-width: calc(100vw - 20px);
    padding: 30px 10px;

    .logo-container {
      padding: 0 20px;
      top: -12px;

      svg {
        height: 25px;
      }
    }

    .columns {
      display: block;

      > div:first-child {
        margin-bottom: 60px;
      }

      .divider {
        display: none;
      }
    }

    .bullet {
      font-size: 15px;
    }
  }
}
