$help-popup-button-size: 26px;
$help-popup-button-size-form-input: 20px;

.help-popup {
  height: $help-popup-button-size;
  position: relative;
  width: $help-popup-button-size;
  z-index: 10;

  &-button {
    align-items: center;
    background-color: $gray200;
    border: none;
    border-radius: 50%;
    color: $gray400;
    cursor: pointer;
    display: flex;
    font-weight: $font-weight-bold;
    height: 100%;
    justify-content: center;
    line-height: 1;
    transition: all .15s ease-in;
    user-select: none;
    width: 100%;

    .active & {
      background-color: $blue-primary;
      color: $white;
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  &-content-wrap {
    bottom: calc(100% + 12px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 50%;
    transition: opacity .15s ease-in;
    z-index: 10;

    &::before {
      @include triangle(down, 14px, 10px, rgba(0, 0, 0, .1));

      position: absolute;
      right: -7px;
      top: 0;
    }

    &::after {
      @include triangle(down, 10px, 6px, $white);

      position: absolute;
      right: -5px;
      top: 0;
    }

    .active & {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-content {
    background-color: $white;
    border-radius: 8px;
    bottom: 0;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
    color: $text-tertiary;
    font-size: 12px;
    line-height: 1.2;
    max-width: 400px;
    padding: 10px 15px;
    position: absolute;
    right: -($help-popup-button-size * 0.5);
    width: max-content;

    @media screen and (width <= 420px) {
      max-width: unset;
      width: calc(100vw - 20px);
    }
  }
}

// Alt style for form inputs
.z-form-input-wrap,
.z-form-textarea-wrap,
.z-form-richtext-wrap,
.z-form-select {
  .help-popup {
    height: $help-popup-button-size-form-input;
    position: absolute;
    right: 1px;
    top: 1px;
    width: $help-popup-button-size-form-input;

    &-button {
      align-items: start;
      border-radius: 0 8px;
    }

    &-content {
      right: -($help-popup-button-size-form-input * 0.5);
    }
  }
}
