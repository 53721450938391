@mixin btn-join-left {
  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-left-radius: 0;

  &::before {
    background-color: $border-color;
    bottom: 5px;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 5px;
    width: 1px;
  }
}

@mixin btn-join-right {
  border-bottom-right-radius: 0;
  border-right: 0;
  border-top-right-radius: 0;
}

@mixin reset-link {
  &,
  &:link,
  &:active,
  &:focus,
  &:visited,
  &:hover {
    box-shadow: none;
    color: inherit;
    outline: none;
    text-decoration: none;
    @content;
  }
}

@mixin triangle($direction, $width, $height, $color) {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  transform: scale(.9999);
  width: 0;

  @if $direction == up {
    border-color: transparent transparent $color;
    border-width: 0 $width * 0.5 $height;
  }

  @if $direction == down {
    border-color: $color transparent transparent;
    border-width: $height $width * 0.5 0;
  }

  @if $direction == left {
    border-color: transparent $color transparent transparent;
    border-width: $height * 0.5 $width $height * 0.5 0;
  }

  @if $direction == right {
    border-color: transparent transparent transparent $color;
    border-width: $height * 0.5 0 $height * 0.5 $width;
  }
}

@mixin responsive($prop, $default, $lg: null, $md: null, $xl: null, $xxl: null, $hd: null) {
  #{$prop}: $default;

  @if $md {
    @include media-breakpoint-up(md) {
      #{$prop}: $md;
    }
  }

  @if $lg {
    @include media-breakpoint-up(lg) {
      #{$prop}: $lg;
    }
  }

  @if $xl {
    @include media-breakpoint-up(xl) {
      #{$prop}: $xl;
    }
  }

  @if $xxl {
    @include media-breakpoint-up(xxl) {
      #{$prop}: $xxl;
    }
  }

  @if $hd {
    @include media-breakpoint-up(hd) {
      #{$prop}: $hd;
    }
  }
}
