.site-footer {
  background-color: $white;
  border-top: 1px solid $border-color;
  color: $text-secondary;
  padding: 30px 15px 100px;
  position: relative;
  z-index: 15;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  nav a {
    font-weight: $font-weight-bold;
  }

  @include media-breakpoint-up(md) {
    padding: 36px 30px 50px;
  }

  @include media-breakpoint-up(lg) {
    padding: 36px 80px 50px;
  }

  &-logo {
    img {
      display: block;
      height: 25px;
    }
  }

  &-meta {
    font-size: 12px;
  }
}
