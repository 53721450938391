.widget-metrics-container {
  min-height: 250px;
  position: relative;

  .date-controls {
    flex: 1;
  }

  .controls-container {
    select {
      flex: 0 0 135px;
    }

    input {
      flex: 0 0 130px;
      max-width: 155px;
    }
  }

  .chart-container {
    height: 250px;
    position: relative;
    width: 100%;
  }

  .table-container {
    max-width: 100%;
    overflow-x: auto;
  }

  .by-host tbody tr {
    &,
    & input {
      cursor: pointer;
    }

    &.disabled {
      color: #d0d0d0;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
