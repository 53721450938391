.badge {
  color: $text-primary;
  font-size: 14px;

  // letter-spacing: .015em;
  padding: 7px 8px;

  // text-transform: uppercase;
  transition: background-color .15s linear;
  border-radius: 6px;

  &.clickable {
    cursor: pointer;
  }

  @each $name, $normal, $hover in $badge-colors {
    &.#{$name} {
      background-color: $normal;

      &.clickable:hover {
        background-color: $hover;
      }
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 12px;
    padding: 4px 8px;
  }
}
