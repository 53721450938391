.sticky-header {
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 15%);
  height: 80px;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: height .2s ease;
  z-index: 1;

  &-container {
    height: 1px;
    width: 100%;
  }
}
