.image-promo-block {
  @include block-padding;

  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .image img {
    border-radius: 8px;
    display: block;
    max-width: 100%;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .image {
      flex: 0 0 35%;
    }
  }

  @include media-breakpoint-up(xl) {
    gap: 160px;
  }

  .body {
    @include body-text;

    h2 {
      @include responsive(font-size, 36px, $md: 38px, $xl: 57px);
      @include display-hed;

      margin-bottom: 1.5rem;
    }
  }

  .attr {
    padding-left: 1.5em;
    position: relative;

    @include responsive(font-size, 24px, $xl: 33px);

    &::before {
      content: '—';
      position: absolute;
      left: 0;
    }

    .name {
      font-weight: 600;
      color: $primary;
    }

    .title {
      @include responsive(font-size, 16px, $xl: 21px);

      align-items: center;
      display: flex;
      letter-spacing: 1.15px;
      text-transform: uppercase;

      .logo {
        display: block;
        margin-left: 10px;

        @include responsive(height, 20px, $xl: 30px);
      }
    }
  }
}
