.loading-indicator {
  animation: rotation 1.2s ease infinite;
  border: 4px solid rgb(80 80 80 / 25%);
  border-radius: 50%;
  border-top-color: $primary;
  height: 50px;
  width: 50px;
}

.loading-overlay {
  align-items: center;
  background-color: rgba(#fff, .6);
  inset: 0;
  display: flex;
  justify-content: center;
  opacity: 1;
  padding: 40px 0;
  position: absolute;
  transition: opacity .3s linear;
  z-index: 10;

  &.bg-lt {
    background-color: rgba($bg-lt, .6);
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
