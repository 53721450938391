#zeitcaster-widget {
  &,
  & body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body::after {
    border: 1px solid $gray300;
    inset: 0;
    content: '';
    pointer-events: none;
    position: fixed;
    z-index: 99;
  }

  .widget-header-wrap {
    position: fixed;
    width: 100%;
    z-index: 20;
  }

  .widget-header-spacer {
    height: 76px;

    @include media-breakpoint-down(md) {
      height: 59px;
    }
  }

  .listings-map-container {
    top: 75px;
  }

  .sticky-header {
    top: 76px;

    @include media-breakpoint-down(md) {
      top: 57px;
    }
  }
}
