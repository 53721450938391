
.profile.table {
  thead th {
    border-bottom: 1px solid $gray300 !important;
    vertical-align: middle;
    font-size: .85rem;

    a,
    a:visited {
      color: var(--bodytext);
    }

    a:hover {
      color: $green400;
      text-decoration: underline;
    }

    a {
      text-decoration: none;
    }
  }

  th.asc a {
    &::before {
      content: "\2227";
      padding-right: .25rem;
    }
  }

  th.desc a {
    &::before {
      content: "\2228";
      padding-right: .25rem;
    }
  }

  td,
  th {
    padding: .25rem;
    vertical-align: middle;
    border-top: 0;
  }

  .c {
    text-align: center;
  }

  .l {
    text-align: left;
  }

  tr.even {
    background: $gray50;
  }

  .alert {
    margin-bottom: 0;
  }
}
