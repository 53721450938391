.saved-items-empty-message {
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;

  @media screen and (width <= 810px) {
    margin: 0 30px;
  }

  @include media-breakpoint-up(xl) {
    align-items: center;
    display: flex;
    max-width: 750px;
    padding-top: 130px;
    text-align: left;
  }

  &-image {
    display: block;
    margin: 0 auto 70px;
    max-width: 170px;

    @include media-breakpoint-up(xl) {
      flex: 0 0 330px;
      margin: 0 70px 0 0;
      max-width: 330px;
    }
  }

  &-text-container {
    @include media-breakpoint-up(xl) {
      flex: 1;
    }
  }

  &-text-primary {
    font-size: 17px;
    font-weight: $font-weight-black;
    line-height: 1.6;
    margin: 0 0 36px;

    @include media-breakpoint-up(xl) {
      font-size: 22px;
    }
  }
}
