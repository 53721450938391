.table-block {
  @include block-padding;

  background-color: $white;

  h2 {
    @include responsive(font-size, 42px, $xl: 55px);
    @include display-hed;
  }

  .description {
    @include body-text;
  }

  .item-container {
    @include responsive(margin-top, 30px, $xl: 50px);
  }

  .item {
    align-items: start;
    border-top: 1px solid $border-color;
    display: flex;
    gap: 20px;

    @include responsive(flex-direction, column, row, row);
    @include responsive(padding, 30px 0, $md: 15px 0, $xl: 30px 0);

    .label {
      align-items: center;
      display: flex;
      font-weight: 600;
      text-transform: uppercase;
      width: 100%;

      @include responsive(font-size, 21px, $lg: 24px);
      @include responsive(gap, 10px, $lg: 14px);
      @include responsive(flex, 1, $md: 0 0 50%, $xxl: 0 0 40%);

      .icon-container {
        align-items: center;
        display: flex;
        flex: 0 0 50px;
        justify-content: center;
      }

      .icon {
        font-size: 35px !important;
      }

      svg {
        display: block;
      }
    }

    .description {
      max-width: 650px;
    }
  }
}
