$listings-card-padding: 12px;

.listings-wrap {
  background-color: $bg-lt;
  min-height: calc(100vh - 140px);
  padding: 40px;
  position: relative;
}

.listings-container {
  max-width: 728px;
  min-height: 200px;
  position: relative;

  // TODO remove when mobile map styles are implemented
  @include media-breakpoint-down(lg) {
    margin: 0 auto;
  }
}

.listings-map-container {
  inset: 140px 0 0 800px;
  position: fixed;
  z-index: 10;

  @include media-breakpoint-down(md) {
    top: 0;
  }

  // TODO remove when mobile map styles are implemented
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.listings-card {
  background-color: $white;
  border-radius: 8px;
  cursor: pointer;
  padding: $listings-card-padding;
  transition: all .15s linear;

  &:hover,
  &.shadow {
    box-shadow: 0 8px 28px rgb(0 0 0 / 12%);
  }

  @include media-breakpoint-down(md) {
    padding-left: $page-horizontal-padding;
    padding-right: $page-horizontal-padding;
  }

  .lc-top {
    display: flex;
    flex-wrap: wrap;
  }

  .lc-icon {
    align-items: center;
    background-color: $bg-lt;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    color: $text-primary;
    display: flex;
    flex: 0 0 284px;
    height: 142px;
    justify-content: center;
    position: relative;

    &.sm {
      flex: 0 0 196px;
      height: 98px;
    }

    &.full-width {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      flex: 0 0 calc(100% + var(--page-horizontal-padding) * 2);
      margin: calc(var(--page-horizontal-padding) * -1);
      margin-bottom: var(--page-horizontal-padding);
    }

    a {
      inset: 0;
      color: inherit;
      position: absolute;
      text-decoration: none;
    }

    .flag {
      left: 0;
      position: absolute;
      top: 10px;
    }
  }

  .lc-main {
    color: $text-secondary;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 $listings-card-padding;

    h5,
    h6 {
      font-weight: $font-weight-bold;

      a {
        text-decoration: none;
      }
    }

    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 15px;
    }

    > :first-child {
      flex: 1;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  .lc-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;

    .bookmark-link {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
      transition: all .15s linear;

      &.active {
        color: $primary;
      }

      @include media-breakpoint-up(lg) {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 34px;
        justify-content: center;
        width: 34px;

        &:hover {
          background-color: $bg-lt;
        }
      }
    }

    svg {
      display: block;
      float: right;
    }
  }

  footer {
    align-items: center;
    border-top: 1px solid $border-color-light;
    display: flex;
    justify-content: space-between;
    margin-top: $listings-card-padding;
    padding-top: $listings-card-padding;
  }
}

.listings-header-wrap {
  position: sticky;
  top: 0;
  z-index: 20;
}

.listings-divider {
  @extend .hairline-divider;

  color: $zc300;
  font-size: 12px;
  font-weight: $font-weight-bold;

  > div {
    &::before,
    &::after {
      background-color: $zc300;
    }
  }
}

@include media-breakpoint-down(md) {
  .listings-header-wrap {
    top: -95px;
  }

  .listings-wrap {
    padding: 30px 0;
  }

  .listings-container > header {
    padding: 0 $page-horizontal-padding;
  }

  .listings-divider {
    margin-left: 10px;
    margin-right: 10px;
  }
}
