.big-message {
  border: 2px solid $primary;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 12%);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100vw - 30px);
  padding: 20px;

  &-icon {
    margin: 0 auto 30px;
    width: 50px;

    svg {
      display: block;
      width: 100%;
    }
  }

  &-heading {
    color: $text-primary;
    font-size: 22px;
    font-weight: $font-weight-black;
    text-align: center;
  }

  &-body {
    font-size: 18px;
  }

  p:last-child {
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    border-width: 3px;
    display: flex;
    padding: 60px;

    &-icon {
      margin: 0 60px 0 0;
      flex: 0 0 75px;
    }

    &-heading {
      text-align: left;
    }
  }

  @include media-breakpoint-up(xl) {
    padding: 150px;
  }
}
