// Styles for wagtail blocks, pages.
// Standardize on wagtail- class names.
.wagtail-color-block-bg-photo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--img-desktop);

  @include media-breakpoint-down(sm) {
    background-image: var(--img-mobile);
  }
}

.wagtail-cta-button-wrap {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: auto;
}

.wagtail-cta-section-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.wagtail-cta-paragraph {
  font-weight: 300;
  line-height: 1.625;
  padding: 1% 10%;
}

.wagtail-landing-page {
  min-height: 600px;
  max-width: 1280px;
}

.wagtail-marketing-content-block {
  min-height: 600px;
  padding: 3rem 3%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  line-height: 2;

  ul {
    text-align: left;
    padding: 80px;
    margin-left: auto;
    margin-right: auto;
  }
}

.wagtail-landing-page-search-icon {
  position: relative;
  right: 179px;
  bottom: -3px;
  color: lightgray;
}

.wagtail-marketing-detail-page-wrap {
  background-color: $bg-lt;
  min-height: 60vh;
  position: relative;
}

.column-block {
  margin: auto;
  max-width: 90%;

  .section-heading {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.4rem;
  }

  div .block-paragraph {
    padding-bottom: 20px;
  }

  .block-fa-icon {
    font-size: 3rem;
    line-height: 1.6;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1080px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 100%;
  }
}

.search-block {
  @include media-breakpoint-up(lg) {
    position: relative;
    background-color: $legacy-dark-blue;

    .search-interior {
      position: absolute;
      top: -50%;
      left: 20%;
      width: 60%;
      background-color: $legacy-bright-blue;
    }
  }

  @include media-breakpoint-up(sm) {
    height: 180px;
  }

  .btn-z.primary {
    background-color: $legacy-bright-green;
    border: none;
  }

  background-color: $legacy-bright-blue;
  height: 300px;
}

.wagtail-bg-blue {
  background-color: $blue-primary;
}

.wagtail-bg-blue-old {
  background-color: #02334e;
}

.wagtail-text-blue {
  color: $blue-primary;
}

.wagtail-legacy-bg-blue {
  background-color: $legacy-dark-blue;
}

.wagtail-legacy-text-blue {
  color: $legacy-dark-blue;
}

.wagtail-legacy-bg-green {
  background-color: $legacy-bright-green;
}

.wagtail-legacy-bg-bright-blue {
  background-color: $legacy-bright-blue;
}

.wagtail-text-legacy-bright-blue {
  color: $legacy-bright-blue !important;
}

.carousel-padding {
  padding: 5% 10%;
}

.carousel-item {
  background-color: white;
  color: black;
  border-radius: 12px;
}
