.clearable-tag {
  align-items: center;
  background-color: $blue-superlight;
  border: 1px solid rgba($blue-primary, 25%);
  border-radius: 2px;
  color: $text-secondary;
  display: inline-flex;
  font-size: 14px;
  line-height: 1;

  .text {
    padding: 2px 6px;
  }

  .close {
    border-left: 1px solid rgba($blue-primary, 25%);
    cursor: pointer;
    font-size: 18px;
    padding: 2px 6px;
    transition: all .15s linear;
    user-select: none;

    &:hover {
      background-color: $blue-primary;
      color: $white;
    }
  }
}
