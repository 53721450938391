.event-dates-list {
  &-pages-wrap {
    margin: 0 -10px;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      margin: 0 -5px;
    }
  }

  &-pages-container {
    display: flex;
    position: relative;
    transition: left .4s ease-in-out;
  }

  &-page {
    flex: 0 0 100%;
    padding: 0 10px;

    @include media-breakpoint-down(sm) {
      flex: 0 0 calc(100% - 30px);
      padding: 0 5px;
    }
  }
}
