.z-input {
  appearance: none;
  background-color: $bg-med;
  border: 1px solid $bg-med;
  border-radius: 50rem;
  box-shadow: none;
  color: $text-primary;
  font-size: 16px;
  font-weight: $font-weight-bold;
  height: 37px;
  line-height: 1;
  outline: 0;
  padding: 8px 18px 9px;
  position: relative;
  transition: all .15s linear;

  &::placeholder {
    color: $text-tertiary;
    font-weight: normal;
  }

  &.lg {
    height: 56px;
    padding: 17px 40px 19px;
  }

  &.outline {
    background-color: transparent;
    border-color: $border-color;
    color: $text-secondary;
    font-weight: $font-weight-normal;
    position: relative;

    &:focus {
      border-color: $primary;
    }

    .error & {
      border-color: $error-color;
    }
  }
}

.outline-label {
  background-color: $white;
  color: $text-secondary;
  display: block;
  font-size: 12px;
  left: 35px;
  line-height: 1;
  padding: 0 5px;
  position: absolute;
  top: -6px;

  &.focused {
    color: $primary;
  }

  .error & {
    color: $error-color;
  }
}

.z-form {
  $input-border-color: $gray400;
  $focused-border-color: $black;

  &-hint-container {
    color: $text-tertiary;
    display: flex;
    font-size: 12px;
    line-height: 1.1;
    margin-top: 4px;
    padding: 0 16px;
  }

  &-hint-text {
    flex: 1;
  }

  &-char-count {
    margin-left: 20px;
    opacity: 0;
    transition: opacity .2s ease-out;

    .focused & {
      opacity: 1;
    }
  }

  &-disabled {
    cursor: not-allowed;
    opacity: .3;
    user-select: none;
  }

  &-clear-button,
  &-select-handle {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      fill: $gray300;
      transition: all .15s linear;
    }

    &:hover svg {
      fill: darken($gray300, 15%);
    }

    .focused & svg {
      fill: $focused-border-color;
    }
  }

  &-multi-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-multi {
    &-label {
      font-weight: $font-weight-bold;

      .required &::after {
        color: $error-color;
        content: ' *';
        font-weight: $font-weight-bold;
      }
    }

    &.horizontal {
      display: flex;
      flex-wrap: wrap;

      .z-form-multi-item {
        margin-bottom: 15px;
        margin-right: 15px;
      }
    }
  }

  // Text input

  &-input,
  &-textarea,
  &-richtext {
    &-wrap {
      position: relative;
    }

    appearance: none;
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: 8px;
    box-shadow: none;
    color: $text-primary;
    display: block;
    font-size: 16px;
    line-height: 1;
    outline: 0;
    padding: 24px 16px 7px;
    position: relative;
    transition: all .15s linear;
    width: 100%;

    &:focus,
    .focused & {
      border-color: $focused-border-color;
    }

    .invalid & {
      border-color: $error-color;
    }

    &-label {
      color: $text-tertiary;
      left: 16px;
      line-height: 1;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all .2s ease-out;
      user-select: none;

      .required &::after {
        color: $error-color;
        content: ' *';
        font-weight: $font-weight-bold;
      }

      .focused &,
      .has-val & {
        font-size: 12px;
        top: 16px;
      }

      .invalid & {
        color: $error-color;
      }
    }
  }

  &-textarea,
  &-richtext {
    height: 250px;
    line-height: 1.5;

    &-label {
      top: 28px;
      z-index: 1;
    }

    &-wrap::after {
      background-color: $white;
      border-radius: 8px 8px 0 0;
      content: '';
      height: 24px;
      left: 1px;
      position: absolute;
      right: 1px;
      top: 1px;
      z-index: 0;
    }
  }

  // Rich text / Quill overrides
  &-richtext {
    &-label {
      top: 70px;

      .focused &,
      .has-val & {
        font-size: 12px;
        top: 58px;
      }
    }

    &-wrap::after {
      display: none;
    }
  }

  &-quill-container {
    display: flex;
    flex-direction: column;
  }

  &-richtext-wrap {
    .ql-toolbar {
      border-color: $input-border-color;
      border-radius: 8px 8px 0 0;

      .ql-formats:not(:last-child) {
        border-right: 1px solid $border-color;
        margin-right: 5px;
        padding-right: 5px;
      }

      .ql-formats button {
        position: relative;

        &::after {
          background: black;
          border-radius: 4px;
          color: $white;
          cursor: default;
          font-size: 14px;
          left: 50%;
          opacity: 0;
          padding: 2px 5px;
          pointer-events: none;
          position: absolute;
          top: calc(100% + 10px);
          transform: translateX(-50%);
          transition: opacity .15s linear;
          width: max-content;
          z-index: 10;
        }

        $tooltip-content: (
          '.ql-bold': 'Bold',
          '.ql-italic': 'Italic',
          '.ql-list[value=bullet]': 'Bulleted List',
          '.ql-list[value=ordered]': 'Numeric List',
          '.ql-link': 'Insert Link',
          '.ql-clean': 'Clear Formatting',
        );

        @each $selector, $content in $tooltip-content {
          &#{$selector} {
            &::after { content: $content; }
            &:hover::after { opacity: 1; }
          }
        }
      }
    }

    .ql-container {
      @extend .z-form-richtext;

      border-radius: 0 0 8px 8px;
      flex: 1;
      font-family: $font-family-sans-serif;
      height: unset;
      overflow: hidden;
    }

    .ql-editor {
      padding: 0;

      p,
      ul,
      ol {
        margin-bottom: .75em;
      }
    }

    .ql-tooltip {
      font-size: 13px;
    }

    &.focused {
      .ql-toolbar,
      .ql-container {
        border-color: $focused-border-color;
      }
    }

    &.invalid {
      .ql-toolbar,
      .ql-container {
        border-color: $error-color;
      }
    }
  }

  // ButtonChoiceField

  &-choice-button {
    background-color: $white;
    border: 2px solid $input-border-color;
    border-radius: 8px;
    color: $text-primary;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-left: 24px;
    padding: 15px 30px;
    position: relative;
    transition: all .15s linear;
    user-select: none;

    &:first-child {
      margin-left: 0;
    }

    &.selected {
      background-color: $blue-superlight;
      border-color: $blue-primary;
    }

    &.invalid {
      border-color: $error-color;
      color: $error-color;
    }

    > input {
      position: absolute;
      visibility: hidden;
    }

    @include media-breakpoint-down(md) {
      margin-left: 12px;
      padding: 10px 15px;
    }
  }

  // Checkbox

  &-checkbox {
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    height: 24px;
    line-height: 22px;
    padding-left: 34px;
    position: relative;
    user-select: none;

    &::before {
      align-items: center;
      background-color: $blue-superlight;
      border: 2px solid rgba($blue-primary, 25%);
      border-radius: 4px;
      box-sizing: border-box;
      color: $white;
      content: '';
      display: flex;
      font-size: 18px;
      height: 24px;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 24px;
    }

    &.checked::before {
      background-color: $blue-primary;

      // content: '⎷';
      // font-weight: $font-weight-bold;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2215%22%20height%3D%2212%22%20viewBox%3D%220%200%2015%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.8695%201.52424L5.51421%2011.8558C5.43136%2011.9481%205.31857%2012%205.2009%2012C5.08324%2012%204.97045%2011.9481%204.88759%2011.8558L0.130532%206.60232C0.0469906%206.51082%200%206.38625%200%206.25631C0%206.12637%200.0469906%206.00181%200.130532%205.9103L0.748331%205.22802C0.83119%205.13576%200.943981%205.08387%201.06164%205.08387C1.17931%205.08387%201.2921%205.13576%201.37496%205.22802L5.19649%209.44839L13.625%200.140195C13.7996%20-0.0467318%2014.0771%20-0.0467318%2014.2517%200.140195L14.8695%200.832218C14.953%200.923724%2015%201.04829%2015%201.17823C15%201.30817%2014.953%201.43273%2014.8695%201.52424Z%22%20fill%3D%22%23ffffff%22%20%2F%3E%3C%2Fsvg%3E");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      border-color: $blue-primary;
    }

    &.invalid {
      color: $error-color;

      &::before {
        background-color: rgba($error-color, 10%);
        border-color: $error-color;
      }
    }

    > input {
      position: absolute;
      visibility: hidden;
    }
  }

  // Radio

  &-radio {
    @extend .z-form-checkbox;

    &::before {
      border-radius: 50%;
    }

    &.selected::before {
      background-color: $blue-primary;
      background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2010%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%225%22%20cy%3D%225%22%20r%3D%225%22%20fill%3D%22%23ffffff%22%20%2F%3E%3C%2Fsvg%3E");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 10px 10px;
      border-color: $blue-primary;
    }
  }

  // Select

  &-select {
    @mixin option-selected {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2215%22%20height%3D%2212%22%20viewBox%3D%220%200%2015%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.8695%201.52424L5.51421%2011.8558C5.43136%2011.9481%205.31857%2012%205.2009%2012C5.08324%2012%204.97045%2011.9481%204.88759%2011.8558L0.130532%206.60232C0.0469906%206.51082%200%206.38625%200%206.25631C0%206.12637%200.0469906%206.00181%200.130532%205.9103L0.748331%205.22802C0.83119%205.13576%200.943981%205.08387%201.06164%205.08387C1.17931%205.08387%201.2921%205.13576%201.37496%205.22802L5.19649%209.44839L13.625%200.140195C13.7996%20-0.0467318%2014.0771%20-0.0467318%2014.2517%200.140195L14.8695%200.832218C14.953%200.923724%2015%201.04829%2015%201.17823C15%201.30817%2014.953%201.43273%2014.8695%201.52424Z%22%20fill%3D%22%23111111%22%20%2F%3E%3C%2Fsvg%3E");
      background-position: calc(100% - 16px) 50%;
      background-repeat: no-repeat;
    }

    &-menu {
      background-color: $white;
      border: 1px solid $input-border-color;
      border-radius: 8px;
      box-shadow: 0 4px 11px hsl(0deg 0% 0% / 10%);
      max-height: 300px;
      overflow: auto;
      padding: 10px 0;
      position: absolute;
      top: calc(100% + 5px);
      width: 100%;
      z-index: 1;
    }

    &-option {
      line-height: 1;
      padding: 12px;

      &:not(.selected):hover {
        background-color: $blue-superlight;
      }

      &.selected {
        @include option-selected;
      }
    }

    .z-form-clear-button {
      right: 32px;
    }

    // react-select overrides

    .rs__control {
      border-color: $input-border-color;
      border-radius: 8px;
      padding: 17px 0 1px;

      &--is-focused {
        &,
        &:hover {
          border-color: $focused-border-color;
          box-shadow: none;
        }
      }
    }

    &.no-label {
      .rs__control {
        padding: 9px 0;
      }

      .rs__indicators {
        top: 0;
      }
    }

    &.invalid .rs__control {
      border-color: $error-color;
    }

    .rs__value-container {
      padding: 0 16px;
    }

    .rs__single-value {
      margin: 0;
    }

    .rs__input-container {
      margin: 0;
      padding: 0;
    }

    .rs__indicators {
      position: relative;
      top: -6px;
    }

    .rs__indicator-separator {
      display: none;
    }

    .rs__menu {
      // border: 1px solid $input-border-color;
      box-shadow: 0 0 0 1px $input-border-color, 0 4px 11px hsl(0deg 0% 0% / 10%);
      border-radius: 8px;
      padding: 10px 0;
      z-index: 10;
    }

    // .rs__menu-list {
    //   border-radius: 8px;
    //   box-shadow: 0 0 0 1px $input-border-color, 0 4px 11px hsl(0deg 0% 0% / 10%);
    //   padding: 10px 0;
    // }

    .rs__option {
      &--is-selected {
        background-color: $white;
        color: inherit;

        @include option-selected;
      }

      &:hover {
        background-color: $blue-superlight;
      }

      &--is-focused {
        background-color: $blue-superlight;
      }
    }
  }

  // Date

  &-calendar-wrap {
    border-radius: 8px;
    box-shadow: 0 4px 11px hsl(0deg 0% 0% / 10%);
    left: 0;
    max-width: 350px;
    min-width: 280px;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 1;

    .rdrCalendarWrapper {
      display: flex;
    }

    .rdrMonth {
      width: unset;
    }
  }

  // Weekday selector

  &-weekday-selector {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;

    &-item {
      align-items: center;
      background-color: $gray300;
      border-radius: 50%;
      color: $text-tertiary;
      cursor: pointer;
      display: flex;
      font-weight: $font-weight-bold;
      height: 34px;
      justify-content: center;
      position: relative;
      user-select: none;
      width: 34px;

      &.selected {
        background-color: $blue-primary;
        color: $white;
      }

      &.locked {
        cursor: not-allowed;
      }

      .lock-icon {
        background-color: $white;
        border-radius: 2px;
        color: $blue-primary;
        padding: 2px;
        position: absolute;
        right: -4px;
        top: -10px;

        svg {
          display: block;
        }
      }
    }
  }

  // Address fieldset

  &-address-map {
    background-color: #ddd;
    border: 1px solid $input-border-color;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
}
