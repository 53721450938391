.z-modal {
  &-content {
    background-color: $white;
    bottom: auto;
    box-shadow: 0 0 20px $gray300;
    left: 50%;
    max-height: 100%;
    opacity: 0;
    outline: none;
    overflow: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .1s linear;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &-overlay {
    background-color: rgba($gray50, .7);
    inset: 0;
    position: fixed;
    z-index: 20;

    @include media-breakpoint-down(md) {
      background: rgb(0 0 0 / 70%);
    }
  }

  &-interior {
    width: 500px;

    &.wide {
      width: 700px;
    }

    @include media-breakpoint-down(md) {
      &,
      &.wide {
        width: 92vw;
      }
    }
  }

  &-close-button {
    @include reset-link;

    display: block;
    position: absolute;
    right: 12px;
    top: 8px;

    svg {
      display: block;
    }
  }
}
