.flag {
  align-items: center;
  background-color: $accent;
  border-radius: 0 8px 8px 0;
  color: $text-primary;
  display: flex;
  font-size: 14px;
  font-weight: $font-weight-bold;
  height: 30px;
  line-height: 1;
  padding: 0 8px;
  text-transform: uppercase;
  width: max-content;

  &.sm {
    font-size: 12px;
    height: 24px;
    line-height: 16px;
  }

  &.clickable {
    cursor: pointer;
  }
}
