.stats-block {
  @include block-padding;

  background-color: #FBFBFB;
  text-align: center;

  .description {
    @include responsive(margin-bottom, 22px, $xl: 65px);
    @include body-text;

    h2 {
      @include responsive(font-size, 40px, $xl: 60px);
      @include display-hed;

      margin-bottom: 1.5rem;
    }
  }

  .stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .item {
    .value {
      @include responsive(font-size, 40px, $xl: 70px);

      color: $text-primary-alt;
      font-weight: 600;
      line-height: 1.1;
    }

    .label {
      @include responsive(font-size, 12px, $xl: 20px);

      color: $text-primary-alt;
      letter-spacing: 1.15px;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-down(md) {
    .stats-container {
      gap: 40px;
      margin: 0 auto;
      max-width: 400px;
    }

    .item {
      flex: 0 0 calc(50% - 20px);
    }
  }

  @include media-breakpoint-up(md) {
    .item {
      flex: 1;
    }
  }
}
