.dots-nav {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
  }

  &-item {
    background-color: $gray300;
    border-radius: 50%;
    cursor: pointer;
    height: 8px;
    margin: 3px;
    width: 8px;

    &.active {
      background-color: $black;
    }
  }
}
