.saved {
  &-listings-wrap {
    min-height: 60vh;
    padding: 30px 0;

    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }
  }

  &-main {
    background-color: $bg-lt;

    @include media-breakpoint-up(md) {
      min-height: 60vh;
    }
  }
}
