.search-promo {
  background: linear-gradient(310deg, #fff -18%, #e8edf5 35%, #f3f4fd 35%, #d7dbeb 100%);
  position: relative;

  .logo-container {
    // bottom: 10px;
    // position: absolute;
    // right: 10px;

    svg {
      display: block;
    }
  }

  footer {
    border-top-color: darken($gray200, 3%);
  }
}

.listings-card.search-promo .lc-main h5 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin: 6px 0 16px;
}
