.breadcrumb-z {
  align-items: baseline;
  display: flex;

  a {
    @extend .reset-link;

    font-weight: $font-weight-bold;
  }

  i,
  svg {
    display: block;
  }

  .current {
    color: $text-tertiary;
    font-weight: $font-weight-bold;
  }

  &-separator {
    margin: 0 13px;
  }
}
