.saved-search-card {
  &-title {
    font-size: 17px;

    @include media-breakpoint-down(md) {
      font-size: 15px;
    }
  }

  &-title a {
    overflow-wrap: anywhere;
  }

  &-edit-button {
    svg {
      display: block;
    }

    transition: color .15s linear;

    &:hover {
      color: $blue-primary;
    }
  }

  &-title-form-wrap {
    align-items: center;
    display: flex;
    position: relative;
    top: -3px;
  }

  // &-title-form {
  // }

  &-title-input {
    background-color: $bg-med;
    border: 0;
    border-radius: 4px;
    color: $text-primary;
    height: 32px;
    padding: 0 70px 0 8px;
    width: 300px;

    &:focus {
      appearance: none;
      outline: none;
    }

    @include media-breakpoint-down(md) {
      padding-right: 55px;
      width: 200px;
    }
  }

  &-save-link {
    left: 245px;
    position: absolute;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      left: 155px;
    }
  }

  &-filter-line {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      color: $black;
    }
  }

  &-meta {
    color: $text-light-alt;
    font-size: 14px;
    line-height: 1;
    text-align: right;
  }
}
