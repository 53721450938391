// Zeitcaster custom button styles

.btn-z {
  align-items: center;
  appearance: none;
  background-color: $bg-med;
  border: 1px solid $bg-med;
  border-radius: 50rem;
  color: $text-primary;
  display: inline-flex;
  font-size: 14px;
  font-weight: $font-weight-bold;
  height: 37px;
  line-height: 1;
  padding: 8px 18px 9px;
  position: relative;
  text-decoration: none;
  transition: all .15s linear;
  white-space: nowrap;

  &:focus,
  &:active {
    box-shadow: none;
    outline: 0;
  }

  &:hover {
    color: $text-primary;
  }

  // &:not(.disabled):not(.active):hover {
  //   background-color: $bg-lt;
  // }

  // TODO
  // &:focus {
  //   border-color: $primary;
  // }

  &.active {
    background-color: $blue-xlight;
    border-color: $gray400;
  }

  &.subtle-focus.active {
    background-color: $blue-xlight;
    border-color: $blue-xlight;
  }

  &.highlighted {
    border-color: $text-primary;
  }

  &.clear {
    background-color: transparent;
    border-color: transparent;
  }

  &.borderless {
    border: none;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  i:first-child {
    margin-right: 10px;
  }

  i:last-child {
    margin-left: 10px;
  }

  svg {
    display: block;
  }

  // alternate colors
  &.primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    font-weight: $font-weight-normal;
  }

  &.secondary {
    background-color: $white;
    border-color: $white;
    color: $blue-primary;
    font-weight: $font-weight-normal;
  }

  &.danger {
    background-color: $red400;
    border-color: $red400;
    color: $white;
    font-weight: $font-weight-normal;
  }

  &.success {
    background-color: $success;
    border-color: $success;
    color: $white;
    font-weight: $font-weight-normal;
  }

  &.subtle {
    background-color: $white;
    border-color: $gray200;
  }

  &.subtle-nobg {
    background-color: transparent;
    border-color: $gray200;
  }

  &.icon-only {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.circle {
    justify-content: center;
    padding: 0;
    width: 37px;
  }

  &.outline {
    background-color: transparent;
    border-color: $border-color;
    color: $text-secondary;
    font-weight: $font-weight-normal;
    position: relative;

    &:focus {
      border-color: $primary;
    }

    &.primary {
      border-color: $primary;
      color: $primary;
    }

    &.danger {
      border-color: $red400;
      color: $red400;
    }

    &.success {
      border-color: $success;
      color: $success;
    }
  }

  // button groups
  &.join-left {
    @include btn-join-left;
  }

  &.join-right {
    @include btn-join-right;
  }

  &.sm {
    height: 28px;
    line-height: 1;
    padding: 4px 10px 5px;

    &.icon-only {
      padding-left: 2px;
      padding-right: 2px;
    }

    &.circle {
      width: 28px;
    }
  }

  &.lg {
    font-size: 20px;
    height: 64px;
    padding: 0 35px;

    i {
      font-size: 28px !important;
    }

    &.circle {
      width: 64px;
    }
  }

  &.sm-lg {
    @include media-breakpoint-up(sm) {
      font-size: 20px;
      height: 64px;
      padding: 0 35px;

      i {
        font-size: 28px !important;
      }

      &.circle {
        width: 64px;
      }
    }
  }

  &.narrow {
    padding-left: 12px;
    padding-right: 12px;
  }
}

// special case for inputs
input.btn-z {
  padding: 7px 18px 8px;

  &:not(.primary):focus {
    background-color: $blue-xlight;
    border-color: $gray400;
  }

  &.subtle-focus:focus {
    background-color: $blue-xlight;
    border-color: $blue-xlight;
  }
}
