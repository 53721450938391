.image-primary-container {
  position: relative;

  img {
    border-radius: 8px;
    display: block;
    filter: drop-shadow(0 4px 16px rgb(0 0 0 / 12%));
    max-width: 100%;
  }

  .flag {
    left: -10px;
    position: absolute;
    top: 14px;
  }
}
