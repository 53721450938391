
.mistake {
  background: $gray50;
  padding: 3rem;

  label.requiredField {
    color: $primary;
  }
}

.claim {
  padding: 3rem;

  label.requiredField {
    color: $primary;
  }
}

.contact-container {
  background-color: $gray50;
}
