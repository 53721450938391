.subscription-options-block {
  // @include block-padding;
  @include responsive(padding, 50px 15px, $md: 70px 75px, $xl: 70px 140px, $hd: 70px 15%);

  background-color: $bg-lt;

  .description {
    @include body-text;

    margin-bottom: 30px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      margin-bottom: 1.5rem;
    }

    h2 {
      @include responsive(font-size, 18px, $lg: 34px);
    }

    h3 {
      @include responsive(font-size, 16px, $lg: 28px);
    }
  }

  .cards-wrap {
    @include responsive(flex-wrap, wrap, $lg: nowrap);

    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 30px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    .card {
      background-color: $white;
      border: 1px solid $border-color;
      display: flex;
      flex: 1;
      flex-direction: column;
      font-size: 14px;
      justify-content: space-between;
      min-width: 300px;
      padding: 30px 20px 20px;

      h3,
      h5 {
        font-weight: $font-weight-black;
      }

      h4 strong {
        font-weight: $font-weight-black;
      }
    }
  }

  .feature-list {
    // margin-bottom: 0;

    ul {
      padding: 0 0 0 35px;

      @include responsive(font-size, 18px, $md: 22px);

      li {
        line-height: 1.5;
        list-style: none;
        margin-bottom: .5em;
        padding-left: 10px;

        &::marker {
          color: $blue-primary;
          content: '✓';
          font-size: 28px;
          font-weight: $font-weight-black;
          line-height: 20px;
        }
      }
    }
  }
}
