.claim-venue-block {
  // @include block-padding;
  @include responsive(padding, 50px 15px, $md: 70px 75px, $xl: 70px 140px, $hd: 70px 15%);

  background-color: $white;

  .image {
    display: none;

    img {
      display: block;
      max-width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    gap: 30px;

    .image {
      display: block;
      flex: 0 0 200px;
    }
  }

  @include media-breakpoint-up(xl) {
    gap: 75px;

    .image {
      flex: 0 0 300px;
    }
  }

  .description {
    @include body-text;

    margin-bottom: 40px;

    h1 {
      @include responsive(font-size, 22px, $lg: 40px);

      font-weight: 700;
      margin-bottom: 1.5rem;
    }
  }
}
